import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Container } from '@components/global';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Footer from '@sections/Footer';

const ThankYouPage = () => (
  <Layout>
    <Container>
      <ThankYou className="col">
        <h1>Thanks so much</h1>
        <p id="success">Message sent successfully.</p>
        <p>You did the thing. I’ll get back to you soon.</p>
        <Link to="/">Go back to the good stuff.</Link>
      </ThankYou>
    </Container>
    <Footer />
  </Layout>
);

export const ThankYou = styled.div`
  margin-top: 120px;
  text-align: center;

  h1 {
    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 64px;
      line-height: 58px;
      letter-spacing: -4px;
    }
  }

  p {
    color: ${props => props.theme.color.purple.dark};
  }

  #success {
    color: #23dd9a;
  }

  a:link,
  a:visited {
    border-bottom: 1px solid ${props => props.theme.color.purple.light};
    text-decoration: none;
    color: ${props => props.theme.color.purple.regular};
  }

  a:hover {
    color: ${props => props.theme.color.purple.light};
  }
`;

export default ThankYouPage;
